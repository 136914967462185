@import 'assets/styles/mixins.scss';

.topbar {
  background: transparent;
  min-height: rem(64);
  border-bottom: 1px solid $border;
  padding: rem(12) rem(20);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.circleWrapper {
  position: relative;

  p {
    margin: 0;
  }
}

.pulsatingCircle {
  position: absolute;
  left: -5%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 15px;
  height: 15px;
  &:before {
    content: '';
    position: relative;
    display: block;
    width: 200%;
    height: 200%;
    box-sizing: border-box;
    margin-left: -50%;
    margin-top: -50%;
    border-radius: 45px;
    background-color: $red;
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: $red;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.23);
  }
  50%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.3);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(0.3);
  }
}

.info {
  display: flex;
  align-items: flex-end;
  .brand {
    width: 130px;
    height: auto;
    padding: 0.35rem 0;

    @media (max-width: $sm-max-width) {
      margin-left: 30px;
    }
  }
  span[description] {
    text-transform: uppercase;
    font-size: 1.3rem;
    font-weight: bold;
    color: $black;
  }
}

.timer {
  display: flex;
  align-items: center;

  > div:first-child {
    margin: 0 10px 0 0;
    font-size: 16px;
    text-decoration: underline;
    color: $black;
  }
}

.activeWrapper {
  margin-left: auto;
  margin-right: 30px;
  &:hover {
    cursor: pointer;
  }
}
